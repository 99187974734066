/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.modal-backdrop {
  display: none !important;
}

.modal {
  background: rgb(255, 255, 255) !important;
}

.modal-backdrop {
  z-index: -1 !important;
}

.modal-wrapper,
.inner-scroll {
  background: transparent !important;
}

// FULL-CLASSES
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans" !important;
}

ion-button {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.button-surveyor_cancel {
  border: 1px solid #ff4789 !important;
  border-radius: 10px !important;
  color: #ff4789 !important;
  border-color: #ff4789 !important;
  height: 51px !important;
  width: 90% !important;
}

.button-surveyor {
  border: 1px solid #542fdf !important;
  border-radius: 10px !important;
  color: white !important;
  border-color: #542fdf !important;
  background-color: #542fdf;
  height: 51px !important;
  width: 90% !important;
}

a {
  color: rgba(84, 47, 223, 1) !important;
  text-decoration: none !important;
}

select:focus {
  outline: none !important;
  box-shadow: none !important;
}

option:focus {
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus,
input:focus {
  box-shadow: 0 0 0 0 !important;
  outline: none !important;
}

// ::-webkit-scrollbar {
//   width: 0px !important;
// }

// ::-webkit-scrollbar-track {
//   background: #F1F1F1 !important;
// }

// .header-ios ion-toolbar:last-of-type {
//   --border-width: 0px !important;
// }


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #01FF5F !important;
}

ion-modal {
  --box-shadow: none !important;
  box-shadow: none !important;
}

.header-md::after {
  display: none !important;
}

:host(.item-interactive.item-has-focus) {
  --highlight-background: rgba(0, 0, 0, 0) !important;
}

:host(.item-interactive.ion-valid) {
  --highlight-background: rgba(0, 0, 0, 0) !important;
}

:root {
  --greenpool: #ccfde2;
  --bluepool: #04a6a7;
  --purple: #542fdf;
  --blue: #0c3b94;
  --purpura: #a561f3;
  --purplelight: #f0f1f3;
  --whiteblue: #e8eaff;
  --whitegreen: #f2fff5;
  --orangelight: #fffada;
  --pink: #ff4789;
  --graylight: #f8f8fd;
  --whitelight: #e9ebf686;
  --bluelight: #878dba;
}

/* fonts */
.font-nunito {
  font-family: "Nunito", sans-serif !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* size-fonts */
.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-60 {
  font-size: 60px;
}

.font-size-90 {
  font-size: 90px;
}

.line-15 {
  line-height: 15px !important;
}

.line-18 {
  line-height: 18px !important;
}

.line-20 {
  line-height: 20px !important;
}

.line-24 {
  line-height: 24px !important;
}

.line-26 {
  line-height: 26px !important;
}

.line-32 {
  line-height: 32px !important;
}

.line-34 {
  line-height: 34px !important;
}

.line-43 {
  line-height: 43px !important;
}

.line-50 {
  line-height: 50px !important;
}

.line-56 {
  line-height: 56px !important;
}

.line-70 {
  line-height: 70px !important;
}

/* tools */
.z-10 {
  z-index: 10;
}

.h-80 {
  height: 80px;
}

.h-111 {
  height: 111px;
}

.h-130 {
  height: 130px;
}

.min-h-80 {
  min-height: 80px;
}

.w-141 {
  width: 141px;
}

.max-w-960 {
  max-width: 960px;
}

.max-w-32 {
  max-width: 32px;
}

.h-fit {
  height: fit-content !important;
}

.h-51 {
  height: 51px;
}

.h-49 {
  height: 49px;
}

.h-74 {
  height: 74px;
}

.w-51 {
  width: 51px;
}

.w-70 {
  width: 70px;
}

.w-fit {
  width: fit-content !important;
}

.ml-1 {
  margin-left: 6px;
}

.op-0 {
  opacity: 0 !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-100 {
  border-radius: 100px;
}

.box-shadow-none {
  box-shadow: none !important;
  --box-shadow: none !important;
}

.border-y-gray {
  border-bottom: 1px solid var(--purplelight) !important;
  border-top: 1px solid var(--purplelight) !important;
}

.border-x-gray {
  border-left: 1px solid var(--purplelight) !important;
  border-right: 1px solid var(--purplelight) !important;
}

.border-bottom-gray {
  border-bottom: 1px solid var(--purplelight) !important;
}

.border-point-bottom-gray {
  border-bottom: 2px dotted #b0b1b4 !important;
}

.border-top-gray {
  border-top: 1px solid var(--purplelight) !important;
}

.border-bluepool {
  border: 1px solid var(--bluepool);
}

.border-black {
  border: 1px solid #000000 !important;
}

.border-bottom-purple {
  border-bottom: 2px solid var(--purple);
}

.box-shadown-none {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.break-spaces {
  white-space: break-spaces;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-align-right {
  text-align: -webkit-right !important;
}

.text-align-left {
  text-align: -webkit-left !important;
}

.text-align-center {
  text-align: -webkit-center !important;
}

.left-0 {
  left: 0;
}

.top-20 {
  top: 20%;
}

.pl-5px {
  padding-left: 5px;
}

.d-grid {
  display: grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

.bg-orange-light {
  background-color: var(--orangelight);
}

.bg-white-blue {
  background-color: var(--whiteblue);
}

.bg-white-green {
  background-color: var(--whitegreen);
}

.bg-gray-light {
  background-color: var(--graylight);
}

.bg-green-light {
  background-color: var(--greenpool);
}

.bg-purple-light {
  background-color: var(--purplelight);
}

.bg-green {
  --background: #01ff5f !important;
}

.bg-white {
  --background: white !important;
}

.bg-pink {
  background-color: var(--pink);
}

.color-pink {
  color: var(--pink) !important;
}

.color-muted {
  color: #b0b1b4 !important;
}

.color-blue {
  color: var(--blue) !important;
}

.color-purple {
  color: var(--purple) !important;
}

.color-bluepool {
  color: var(--bluepool);
}

.color-black {
  color: black;
}

.color-gray {
  color: gray;
}

.border-purple {
  border: 1px solid var(--purple);
}

.border-grey {
  border: 1px solid #CCCCCC;
}

.border-pink {
  border: 1px solid var(--pink);
}

.form-check-input:checked {
  background-color: #01ff5f !important;
  border-color: #01ff5f !important;
}

.form-check-finished-surveyor:checked {
  background-color: #f60000 !important;
  border-color: #01ff5f !important;
  background: white !important;
}

.bloon-welcome {
  background-image: url(./assets/images/bloon.svg) !important;
  height: 84px !important;
  width: 93px !important;
  display: flex !important;
  padding: 12.8px 23.8px 29.03px 14.64px !important;
}

.text-bloon-welcome {
  font-family: "Lato" !important;
  font-size: 12px !important;
  line-height: 13px !important;
  color: white !important;
}

.header-home {
  --background: white !important;
}

.bg-welcome-1 {
  --background: #231f20 0 0/100% 100% no-repeat !important;
}

.bg-welcome-2 {
  --background: #231f20 0 0/100% 100% no-repeat !important;
}

.bg-welcome-3 {
  --background: #231f20 0 0/100% 100% no-repeat !important;
}

.bg-purple {
  background-color: var(--purple);
}

.color-purple-light {
  color: #878dba !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.balls-carousel-fake-select {
  background: #01ff5f !important;
  border-radius: 50% !important;
  height: 10px !important;
  width: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.balls-carousel-fake {
  background: black !important;
  border: 1px solid white !important;
  border-radius: 50% !important;
  height: 10px !important;
  width: 10px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.btn-group-carousel-welcome {
  display: none !important;
}

.btn-next-welcome {
  --background: transparent !important;
  margin-top: 32px !important;
  color: white !important;
  height: 57px !important;
  width: 90% !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
  margin-bottom: 63px !important;
}

.btn-next-welcome-mobile {
  --background: transparent !important;
  margin-top: 32px !important;
  color: white !important;
  height: 57px !important;
  width: 90% !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
  margin-bottom: 63px !important;
  display: none !important;
}

.title-welcome {
  font-size: 34px !important;
  line-height: 44px !important;
  font-weight: 500 !important;
  padding: 0px 39px 0px 52px !important;
  display: none !important;
}

.title-login-or-register {
  color: black !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}

.text-login-or-register {
  color: black !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
}

.btn-login {
  --background: #542fdf !important;
  color: white !important;
  height: 57px !important;
  --border-radius: 5px !important;
  font-size: 18px !important;
  width: 100% !important;
}

.btn-login-or-register {
  background: white !important;
  border: 1px solid #542fdf !important;
  color: #542fdf !important;
  height: 57px !important;
  border-radius: 5px !important;
  font-size: 18px !important;
}

.input-code-customer {
  width: 54px !important;
  height: 72px !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  background: #e9ebf6 !important;
  border-radius: 5px !important;
  border: 1px solid #ced4da !important;
  text-align: center !important;
}

.input-code-customer:focus {
  width: 54px !important;
  height: 72px !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  border: 1px solid #542fdf !important;
  background: #e9ebf6 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.btn-send-code {
  background: #542fdf !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 18px !important;
  height: 57px !important;
  width: 90% !important;
}

.btn-send-code:disabled {
  background: #e6e6e6 !important;
  border-radius: 5px !important;
  color: #b0b1b4 !important;
  font-size: 18px !important;
  height: 57px !important;
  width: 90% !important;
}

.code-group-class-custom {
  justify-content: space-evenly !important;
}

.dropdown-sidebar-custom {
  background-color: var(--purplelight) !important;
  width: 100% !important;
  height: 63px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.dropdown-detail-custom {
  background-color: #FFFFFF !important;
  width: 100% !important;
  height: 63px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.border-profile-pic-side {
  height: 46px !important;
  width: 46px !important;
  border-radius: 50% !important;
  border: 1px solid #542fdf !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
}

.card-home-indicate {
  min-height: 200px !important;
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #542fdf !important;
  padding: 14px 17px 24px 18px !important;
}

.borde-status {
  border-radius: 100px !important;
  background-color: #ccfde2;
  border-color: #542fdf;
  color: #000000 !important;
}

.card-surveyor-news {
  display: block !important;
  border-radius: 5px !important;
  border: 1px solid #b0b1b4 !important;
  padding: 12px !important;
}

.card-surveyor-finished {
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #b0b1b4 !important;
  padding: 14px 17px 24px 18px !important;
}

.circle-fav-green {
  height: 54px !important;
  width: 54px !important;
  border-radius: 50% !important;
  background-color: #01ff5f !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.btn-indicate-home {
  --background: #542fdf !important;
  color: white !important;
  width: 100% !important;
  border-radius: 5px !important;
  height: 50px !important;
}

.card-balance-home {
  min-height: 140px !important;
  width: 100% !important;
  background: #f8f8fd;
  box-shadow: 0px 2px 4px rgba(78, 86, 140, 0.06);
  border-radius: 12px;
  padding: 18px !important;
}

.circle-balance-blue {
  height: 54px !important;
  width: 54px !important;
  border-radius: 50% !important;
  background-color: #542fdf !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.card-last-added {
  border: 1px solid #b0b1b4 !important;
  filter: drop-shadow(0px 2px 4px rgba(78, 86, 140, 0.06)) !important;
  border-radius: 10px !important;
  width: 100% !important;
  min-height: 208px !important;
}

.padding-card-last-added {
  padding: 16px 16px 0px 16px !important;
}

.status-space-approved {
  border: 1px solid #00d455 !important;
  border-radius: 50px !important;
  color: #00d455 !important;
  padding: 4px 12px 4px 12px !important;
}

.status-space-underAnalysis {
  border: 1px solid #efb51f !important;
  border-radius: 50px !important;
  color: #efb51f !important;
  padding: 4px 12px 4px 12px !important;
}

.status-filter {
  border: 1px solid #3d4ff4 !important;
  border-radius: 50px !important;
  color: #3d4ff4 !important;
  padding: 4px 12px 4px 12px !important;
  align-items: center;
  width: max-content;
  display: flex;
  margin-right: 10px;
}

.status-space-inProgress {
  border: 1px solid #3d4ff4 !important;
  border-radius: 50px !important;
  color: #3d4ff4 !important;
  padding: 4px 12px 4px 12px !important;
}

.status-space-disapproved {
  border: 1px solid #e77e7d !important;
  border-radius: 50px !important;
  color: #e77e7d !important;
  padding: 4px 12px 4px 12px !important;
}

.input-indicate-custom {
  border: 1px solid #c2c3c6 !important;
  border-radius: 5px !important;
  color: black !important;
  height: 57px !important;
  margin-bottom: 14px !important;
  --padding-start: 12px !important;
}

.btn-indicate {
  width: 100% !important;
  height: 57px !important;
  --background: #542fdf !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: lighter !important;
}

.btn-clean-input-indicate {
  width: 100% !important;
  height: 57px !important;
  --background: white !important;
  color: #542fdf !important;
  border: 1px solid #542fdf !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: lighter !important;
  margin-right: 10px !important;
}

.form-check-input-filter:checked {
  background-color: #01ff5f !important;
  border-color: #01ff5f !important;
}

.btn-cancel-filter {
  --background: white !important;
  color: #542fdf !important;
  box-shadow: none !important;
}

.btn-filter {
  --background: #542fdf !important;
  color: white !important;
  height: 51px !important;
  --border-radius: 12px !important;
  margin-bottom: 15px !important;
}

.circle-border-green {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  border: 4px solid #01ff5f !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-indicate-modal-success {
  height: 51px !important;
  width: 100% !important;
  --background: #542fdf !important;
  --border-radius: 12px !important;
  color: white !important;
}

.btn-months-statement {
  background-color: white !important;
  color: #878dba !important;
  height: 26px !important;
  padding: 1px 10px 1px 10px !important;
  border-radius: 10px !important;
  border: 1px solid #878dba !important;
  margin-right: 5px !important;
  margin-bottom: 10px !important;
}

.btn-months-statement-active {
  background-color: white !important;
  color: #542fdf !important;
  height: 26px !important;
  padding: 1px 10px 1px 10px !important;
  border-radius: 10px !important;
  border: 1px solid #542fdf !important;
  margin-right: 5px !important;
  margin-bottom: 10px !important;
}

.card-not-statement {
  background: #f8f8fd !important;
  border-radius: 10px !important;
  height: 110px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #1e2661 !important;
  margin-top: 15px !important;
}

// MODAL-PROPERTY-LIST
.modal-grid {
  margin-left: 5%;
  width: calc(100% - 10%);
}

.transition-updown {
  animation-name: updown;
  animation-duration: 0.5s;
}

.card-header-profile {
  background-color: #f8f8fd !important;
  border-radius: 10px !important;
  height: 130px !important;
  width: 100% !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 51px !important;
  flex-direction: column !important;
}

.border-profile-picture {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50% !important;
  border: 3px solid #01ff5f !important;
  position: absolute !important;
  top: -40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.img-fake-profile {
  height: 68px !important;
  width: 68px !important;
  border-radius: 50% !important;
  background-color: #542fdf !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.photo-profile-logged-custom {
  height: 44px !important;
  width: 44px !important;
  border-radius: 50% !important;
}

.photo-profile-custom {
  height: 75px !important;
  width: 75px !important;
  border-radius: 50% !important;
}

.cam-change-picture-circle {
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
  background-color: white !important;
  height: 28px !important;
  width: 28px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-data-profile-select {
  border-bottom: 3px solid #542fdf !important;
  border-radius: 1px !important;
  background-color: white !important;
  padding: 2px 15px 2px 15px !important;
  color: #542fdf !important;
}

.btn-data-profile-disabled {
  border-bottom: none !important;
  padding: 2px 15px 2px 15px !important;
  color: #878dba !important;
}

.card-profile-data-view {
  border: 1px solid #b0b1b4 !important;
  box-shadow: 0px 2px 4px rgba(78, 86, 140, 0.06) !important;
  border-radius: 12px !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 18px 18px 18px 19px !important;
}

.btn-checkOut-profile {
  width: 90% !important;
  --background: white !important;
  height: 51px !important;
  border: 1px solid #542fdf !important;
  border-radius: 5px !important;
  color: #542fdf !important;
}

.icon-edit-profile {
  position: absolute !important;
  right: 0px !important;
  bottom: -25px !important;
  z-index: 9 !important;
}

.input-edit-profile {
  border: 1px solid #e8e9eb !important;
  border-radius: 9px !important;
  min-height: 57px !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.label-edit-profile {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: black !important;
  margin-bottom: 8px !important;
  margin-top: 14px !important;
}

.btn-save-edit-profile {
  --background: #542fdf !important;
  border-radius: 10px !important;
  color: white !important;
  width: 90% !important;
  height: 51px !important;
  font-weight: bold !important;
}

.btn-add-function-work-data {
  --background: white !important;
  border: 1px solid #542fdf !important;
  --color: #542fdf !important;
  border-radius: 5px !important;
  --padding-bottom: 2px !important;
  --padding-top: 2px !important;
  --padding-end: 8px !important;
  --padding-start: 8px !important;
}

.space-picture-ceo-aboute {
  width: 124.27px !important;
  height: 124.27px !important;
  border-radius: 50% !important;
  border: 3px solid #01ff5f !important;
  position: absolute !important;
  top: -62.13px !important;
  padding: 5px !important;
}

.img-ceo-aboute {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50% !important;
}

.view-profile-ceo-aboute {
  --background: #542fdf !important;
  width: 90% !important;
  height: 51px !important;
  color: white !important;
  border-radius: 5px !important;
  font-size: 16px !important;
}

// ANNOUNCEMENT PAGE
.rental-property-btn {
  padding: 0px 16px;
}

.img-rental-property {
  min-width: 110px;
}

// advertise-property-PAGES
ion-item,
ion-list,
.list-ios {
  --background: var(--graylight) !important;
  background: var(--graylight) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.selector-item_label {
  padding: 10px 18px;
}

.selector-item_radio {
  appearance: none;
  display: none;
}

.selector-item_checkbox {
  appearance: none;
  display: none;
}


.btn-imovel-quitado-active {
  height: 40px !important;
  background-color: var(--purple) !important;
  padding: 0px 16px !important;
  color: white !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
}

.btn-imovel-quitado-disabled {
  height: 40px !important;
  background-color: var(--graylight) !important;
  padding: 0px 16px !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.selector-item_radio:checked+.selector-item_label {
  background-color: var(--purple) !important;
  color: white !important;
  // width: 100% !important;
  border: 1px solid var(--purple) !important;
}

.selector-item_checkbox:checked+.selector-item_label {
  background-color: var(--purple) !important;
  color: white !important;
  // width: 100% !important;
  border: 1px solid var(--purple) !important;
}

.border-radius-custom-photo {
  border-radius: 10px !important;
}

.sr-only {
  display: none;
}

.ion-card-grid {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
}

.tags-input__input-field {
  border-radius: 10px;
  border: 1px solid var(--whitelight);
  height: 51px;
  padding: 1rem;
  background-color: var(--graylight);
}

.tags-input__tag {
  background-color: white !important;
  border-radius: 10px !important;
  padding: 6px 10px !important;
  color: var(--purple);
  display: flex !important;
  align-items: center !important;
  border: 1px solid var(--purple) !important;
}

.tags-input__tag-remove-btn {
  font-size: 21px !important;
}

.span-group-prepend {
  border-radius: 10px 0px 0px 10px;
  padding-left: 10px;
  padding-right: 1px;
  border-right: 0px;
}

//   EDIT-PROPIERTIES
.img-rental-property-edit {
  max-width: 82px;
}

.card-news-photographer {
  border: 1px solid #b0b1b4 !important;
  border-radius: 9px !important;
  padding: 24px 16px !important;
}

.space-status-new-photographer {
  background: #fffada;
  border-radius: 90px;
  padding: 4px 12px !important;
  max-width: 136px !important;
}

.space-status-scheduled-new-photographer {
  background: #e8eaff;
  border-radius: 90px;
  padding: 4px 12px !important;
  max-width: 136px !important;
}

.btn-accept-new-photographer {
  background: #542fdf;
  border-radius: 7.2px;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 20px;
  color: #feffff;
  height: 50px !important;
  width: 100% !important;
}

.btn-refuse-new-photographer {
  background: white;
  border: 1px solid #ff4789 !important;
  border-radius: 7.2px;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 20px;
  color: #ff4789;
  height: 50px !important;
  width: 100% !important;
}

.space-status-finished-photographer {
  background: #ccfde2;
  border-radius: 90px;
  padding: 4px 12px !important;
  max-width: 176px !important;
}

.btn-add-room {
  height: 57px;
  background: #542fdf;
  border-radius: 9px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #feffff;
  width: 100% !important;
}

.input-add-room {
  border: 1px solid #b0b1b4;
  border-radius: 9px;
  height: 74px;
}

.card-add-room {
  background: #e9ebf6;
  border: 1px solid #b0b1b4;
  border-radius: 9px;
  height: 74px;
}

.btn-add-photo-manage-room {
  height: 57px;
  background: #542fdf;
  border-radius: 9px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #feffff;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
}

.icon-deleted-manage-room {
  position: absolute !important;
  top: 4px !important;
  left: 3px !important;
  cursor: pointer !important;
}

.space-info-important {
  background: #f0f1f3 !important;
  padding: 18px 12px !important;
}

.input-gauges-surveyor {
  border: 1px solid #b0b1b4;
  border-radius: 9px;
  height: 47px;
}

.bg-green-light {
  background: #f2fff5 !important;
}

.selected-visit-day {
  border-bottom: 2px solid #542fdf !important;
  color: #542fdf !important;
}

.disabled-visit-day {
  border-bottom: none;
  color: #231f20 !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 55px !important;
  border-radius: 10px !important;
  border: 1px solid #dee2e6 !important;
}

.img-main-announcement {
  max-width: 110px !important;
  max-height: 110px !important;
  height: 110px !important;
  width: 110px !important;
}

.margin-bottom-custom {
  margin-bottom: 75px !important;
}

ion-datetime {
  --background: #fff1f2 !important;
  --background-rgb: 255, 241, 242 !important;

  box-shadow: rgba(var(--purple), 0.3) 0px 10px 15px -3px !important;
}

@media (max-width: 768px) {
  .bg-welcome-1 {
    // --background: url(./assets/images/surface-desktop.png) 0 0/100% 100% no-repeat !important;
    background-image: url(./assets/images/surface-desktop.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 100vh;
  }

  .bg-welcome-2 {
    --background: url(./assets/images/bg-welcome-2.png) 0 0/100% 100% no-repeat !important;
  }

  .bg-welcome-3 {
    --background: url(./assets/images/bg-welcome-3.png) 0 0/100% 100% no-repeat !important;
  }

  .btn-group-carousel-welcome {
    display: flex !important;
  }

  .title-login-or-register {
    position: center !important;
  }

  .margin-border {
    margin: 1vh !important;
  }

  .btn-next-welcome-mobile {
    --background: transparent !important;
    margin-top: 32px !important;
    color: white !important;
    height: 57px !important;
    width: 90% !important;
    border: 1px solid white !important;
    border-radius: 5px !important;
    margin-bottom: 63px !important;
    display: flex !important;
  }

  .btn-next-welcome {
    --background: transparent !important;
    margin-top: 32px !important;
    color: white !important;
    height: 57px !important;
    width: 90% !important;
    border: 1px solid white !important;
    border-radius: 5px !important;
    margin-bottom: 63px !important;
    display: none !important;
  }

  .img-desktop-show {
    display: none !important;
  }

  .title-welcome {
    font-size: 34px !important;
    line-height: 30px !important;
    font-weight: 500 !important;
    padding: 0px 52px 70px 39px !important;
    display: block !important;
    text-shadow: 0.1em 0.1em 0.2em black !important;
  }
}

@media (max-width: 1278px) {
  .bg-welcome-1 {
    // --background: url(./assets/images/surface-desktop.png) 0 0/100% 100% no-repeat !important;
    background-image: url(./assets/images/surface-desktop.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 100vh;
  }

  .bg-welcome-2 {
    --background: url(./assets/images/bg-welcome-2.png) 0 0/100% 100% no-repeat !important;
  }

  .bg-welcome-3 {
    --background: url(./assets/images/bg-welcome-3.png) 0 0/100% 100% no-repeat !important;
  }

  .btn-group-carousel-welcome {
    display: flex !important;
  }
}

@media (min-width: 1278px) {
  .bg-welcome-1 {
    // --background: url(./assets/images/surface-desktop.png) 0 0/100% 100% no-repeat !important;
    background-image: url(./assets/images/surface-desktop.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 100vh;
  }

  .bg-welcome-2 {
    --background: url(./assets/images/k-desktop.png) 0 0/100% 100% no-repeat !important;
  }

  .bg-welcome-3 {
    --background: url(./assets/images/k-desktop.png) 0 0/100% 100% no-repeat !important;
  }
}

// VISTITS-PAGE-BrokerRegistrationComponent
.img-rental-page {
  min-width: 110px;
  max-height: 88px;
  max-width: 135px;
  border-radius: 10px !important;
}

.image-photograper-submit {
  height: 200px !important;
  width: 100% !important;
  border-radius: 10px !important;
}

.background-header {
  background: #FEFFFF !important;
}